import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/Kinetik/Navbar";
import Footer from "../components/Kinetik/Footer";
import PageBanner from '../components/Common/PageBanner';
import OurFeatures from "../components/Kinetik/OurFeatures";

import AboutOne from "../assets/images/about-one.png";
import teaso from "../assets/images/kinetik/teaso.png";
import Pageone from "../assets/images/kinetik/pageone-logo.jpg";
import designertemple from "../assets/images/kinetik/designertemple-logo.png";
import qkom from "../assets/images/kinetik/qkomlogo.png";
const About1 = () => (
    <Layout>
        <SEO title="Über uns" /> 

        <Navbar />

        <PageBanner pageTitle="Über uns" />

        <div className="about-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={AboutOne} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>Our History</h2>
                                <div className="bar"></div>
                                <p>KiNETiK wurde 1997 von Klaus Scheller und Andreas Kurzac gegründet mit dem Ziel, ihre Kunden mit moderner IT-Technik zu beliefern. Dabei war Klaus Scheller spezialisiert auf die Lieferung von Technik für die digitale Druckvorstufe wie sie in Druckereien zum Einsatz kommt. Dazu gehörten z.B. Ausschießlösungen, Farb- sowie Raster-Proof-Lösungen, OPI-Server und leistungsstarke Raster-Image-Prozessoren. Andreas Kurzac befasste sich mit der Entwicklung von Software für Internet-Applikationen und konnte direkt aus seiner früheren Beschäftigung an der TU München Projekte für KiNETiK aquirieren.</p>

                            </div>

                            <p>Diese einmalige Know-How Kombination führte schnell zu den ersten Projekten und Lösungen, die man heute als „Web2Print-Lösung“ bezeichnen würde. Als erstes Web2Print System wurde schon 1998 ein Anzeigensystem zusammen mit der Agentur Leo Burnett für den FIAT Konzern mit allen Marken entwickelt.
Im Jahr 2002 hat Klaus Scheller die Firma verlassen und der Bereich Druckvorstufe wurde eingestellt um sich zu 100% auf die Entwicklung innovativer Software zu konzentrieren. In diesem Jahr wurde die Produktentwicklung der ibright Web2Print Lösung gestartet die zu einer umfangreiche Prozess-Automatisierungs-Plattform weiterentwickelt wurde.</p>

                       
                        </div>
                    </div>
                </div>

                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>What we do</h3>
				<p>KiNETiK ist spezialisiert auf IT Lösungen für den Bereich Medienmanagement, Dokumentenmanagement, Layout-Gestaltung und Layout-Automatisierung im Web-Browser. Wir behaupten, es gibt in diesem Bereich keine Anforderungen, die wir nicht umsetzen können.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Who we are</h3>

                                <p>Kinetik bezeichnet in der Physik die Lehre von der Bewegung und gleichzeitig ist es eine Kunstform. Wir finden, das passt zu uns: Beruflich sind wir Informatiker und entwickeln Software, mit der wir etwas bewegen. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our mission</h3>
                            <p>Wir bei KiNETiK sind Technikbegeistert und haben Spaß daran, Lösungen zu erschaffen, die es so noch nicht gibt und die die neuesten Technologien und Entwicklungen integrieren. Wir arbeiten mit den neuesten Technologien und Sicherheitsstandards und sind technisch am Puls der Zeit. Wir sind erst Glücklich, wenn unsere Lösung für unseren Kunden einen echten Mehrwert leistet.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*       
        <PageBanner pageTitle="Unsere Partner" />
        
        {/*<div>
        <img src={teaso} alt="image" />
		</div>
		
		<p> </p>
		<b> teamwork solutions – Klaus Düsel </b>
		<p> </p>
		<p> Gehen Anforderungen über die reine Technik hinaus berät teamwork solutions unsere Kunden in allen Fragen rund um Strategie, Marketing, Medien und Geschäftsprozessen. </p>
		<p> </p>
		<div>
        <img src={Pageone} alt="image" />
		</div>
		<p> </p>
		<b> pageone GMBH </b>
		<p> </p>
		<p> page one ist unser Partner für die Shop-Integration unserer Online-Design Lösungen. </p>
		<div>
        <img src={designertemple} alt="image" />
		</div>
		<p> </p>
		<b> designertemple GmbH </b>
		<p> </p>
		<p> Die designertemple GmbH realisiert gemeinsam mit der KiNETiK GmbH  innovative Social-Cloud Lösungen. </p>
		<div>
        <img src={qkom} alt="image" />
		</div><p> </p>
		<b> Q!Kom GmbH </b>
		<p> </p>
		<p> Q!Kom ist unser Partner für  Integrationen mit allen Enterprise-Lösungen von IBM, Salesforce und Microsoft. </p>
        <p> </p>
        <OurFeatures />  */}
        <Footer />
    </Layout>
)

export default About1;
